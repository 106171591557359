
// Toolbar constants
export const TYPE_FILTER_LIST = 'TYPE_FILTER_LIST'
export const TYPE_FILTER_INPUT = 'TYPE_FILTER_INPUT'
export const TYPE_FILTER_DATE = 'TYPE_FILTER_DATE'
export const CONDITION_LESS_THAN = 'lt'
export const CONDITION_EQUAL = 'eq'
export const CONDITION_GREATER_THAN = 'gt'
export const CONDITION_IS = 'is'
export const CONDITION_NOT_IS = 'is_not'
export const CONDITION_IN = 'range'
export const CONDITION_NOT_IN = 'out_of_range'
export const CONDITION_CONTAIN = 'like'
export const CONDITION_NOT_CONTAIN = 'not_like'
export const DATA_TYPE_INT = 'int'
export const DATA_TYPE_STRING = 'string'
export const DATA_TYPE_FLOAT = 'float'

// Campaigns constants
export const TYPE_LIVESTREAM = 'livestream'
export const TYPE_COMMENT = 'comment'
export const TYPE_NEWS_NEW = 'new'
export const TYPE_NEWS_OLD = 'old'

export const VN_CHARS = 'ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ'

// Rewards constants
export const TYPE_ROTATION_ITEM_LUCKY = 'LUCKY'
export const TYPE_ROTATION_ITEM_MORE = 'MORE'
export const TYPE_ROTATION_ITEM_GIFT = 'GIFT'

export const TYPE_ARTIFACT     = 1;
export const TYPE_ROTATION     = 2;
export const TYPE_DIAL_NUMBER  = 3;

export const DEFAULT_CONFIRM_LEAVE_MESSAGE = 'Nếu bạn rời khỏi trang này, tất cả những dữ liệu chưa được lưu sẽ bị mất đi. Bạn có chắc chắn muốn rời trang này không?'

export const VALIDATE_EMAIL_REGEX = /^[a-z][a-z0-9_.]{1,}@[a-z0-9]{1,}(\.[a-z0-9]{1,}){1,2}$/
export const VALIDATE_PHONE_REGEX = /((^0|^84|^\+84|^\(\+84\))+([0-9]{2}))+([0-9]{7}|[0-9]{1}\.[0-9]{3}\.[0-9]{3}|[0-9]{1}-[0-9]{3}-[0-9]{3}|[0-9]{1}\s[0-9]{3}\s[0-9]{3}$)/

// Role constants
export const ROLE_OWNER = 'owner';
export const ROLE_ADMIN = 'admin';
export const ROLE_USER = 'user';

// Contract status
export const CONTRACT_STATUS_PENDING = 1;
export const CONTRACT_STATUS_CANCELED = 2;
export const CONTRACT_STATUS_PAID = 3;
export const CONTRACT_STATUS_EXPIRED = 4;

// Order Status Text
export const ORDER_STATUSES = [
  { value: 'draft', text: 'Nháp' },
  { value: 'processing', text: 'Đang xử lý' },
  { value: 'delivering', text: 'Đang giao hàng' },
  { value: 'canceled', text: 'Đã hủy' },
  { value: 'complete', text: 'Hoàn thành' },
  { value: 'waiting_for_refund', text: 'Chờ hoàn' },
  { value: 'refunded', text: 'Đã hoàn' }
];

const affiliateProgramStatusUpcoming = 'upcoming';
const affiliateProgramStatusHappening = 'happening';
const affiliateProgramStatusEnded = 'ended';

export const AFFILIATE_PROGRAM_STATUS_UPCOMING = affiliateProgramStatusUpcoming;
export const AFFILIATE_PROGRAM_STATUS_HAPPENING = affiliateProgramStatusHappening;
export const AFFILIATE_PROGRAM_STATUS_ENDED = affiliateProgramStatusEnded;

export const AFFILIATE_STATUSES = {
  [affiliateProgramStatusUpcoming]: 'Sắp tới',
  [affiliateProgramStatusHappening]: 'Đang xảy ra',
  [affiliateProgramStatusEnded]: 'Đã kết thúc'
};

export const GROUP_ORDER_STATUS = {
  draft: [
    { value: 'draft', text: 'Nháp' },
    { value: 'processing', text: 'Đang xử lý' },
    { value: 'canceled', text: 'Đã hủy' }
  ],
  processing: [
    { value: 'processing', text: 'Đang xử lý' },
    { value: 'delivering', text: 'Đang giao hàng' },
    { value: 'complete', text: 'Hoàn thành' },
    { value: 'canceled', text: 'Đã hủy' }
  ],
  delivering: [
    { value: 'delivering', text: 'Đang giao hàng' },
    { value: 'complete', text: 'Hoàn thành' },
    { value: 'canceled', text: 'Đã hủy' }
  ],
  complete: [
    { value: 'complete', text: 'Hoàn thành' },
    { value: 'waiting_for_refund', text: 'Chờ hoàn' }
  ],
  canceled: [
    { value: 'canceled', text: 'Đã hủy' }
  ],
  waiting_for_refund: [
    { value: 'waiting_for_refund', text: 'Chờ hoàn' },
    { value: 'refunded', text: 'Đã hoàn' }
  ],
  refunded: [
    { value: 'refunded', text: 'Đã hoàn' }
  ]
}


// Withdrawal Order Status Text
export const WITHDRAWAL_ORDER_STATUSES = [
  { value: 'pending', text: 'Chờ giải quyết' },
  { value: 'approved', text: 'Chấp nhận' },
  { value: 'rejected', text: 'Từ chối' }
];

// Order Payment Status Text
export const ORDER_PAYMENT_STATUSES = {
  cod: 'Trả tiền khi nhận hàng',
  transfer: 'Chuyển khoản',
  vnpay: 'VNPAY'
}
export const ORDER_PAYMENT_METHOD_VNPAY = 'vnpay';
export const ORDER_PAYMENT_METHOD_COD = 'cod';

const shippingUnitGhtk = 'ghtk';
const shippingInnerCity = 'inner_city';
const shippingBasic = 'basic';

export const SHIPPING_UNIT_GHTK = shippingUnitGhtk;
export const SHIPPING_INNER_CITY = shippingInnerCity;
export const SHIPPING_BASIC = shippingBasic;

export const TRANSPORTATION_SERVICES = {
  [shippingInnerCity]: 'Giao nội thành',
  [shippingBasic]: 'Giao hàng tiêu chuẩn',
  [shippingUnitGhtk]: 'Giao hàng tiết kiệm'
}

/*
 * auto : là SA gắn cho ctv hoặc, ctv đủ kpi có thể đc nhận voucher tự động từ hệ thống
 * manual: sẽ được hiển thị ra ngoài cho ctv tự lưu lại
 */
export const VOUCHER_SAVE_TYPE_AUTO = 'auto';
export const VOUCHER_SAVE_TYPE_MANUAL = 'manual';

export const VOUCHER_ACTION_TYPE_FOR_ADMIN = 'admin';
export const VOUCHER_ACTION_TYPE_FOR_SYSTEM = 'system';

export const DISCOUNT_TYPE_ORDER = 'order';
export const DISCOUNT_TYPE_PRODUCT = 'product';

export const VOUCHER_TYPE_FOR_ORDER = 'order';
export const VOUCHER_TYPE_FOR_PRODUCT = 'product';

export const NOT_SELECTED = '';
export const NOT_ASSIGNED = 'not_assigned';

export const ADMIN_REGIONS = ['HN', 'SG'];

export const BRANDS = ['DHC', 'Reihaku Hatomugi', 'Ciracle', 'Avance', 'Chifure', 'Haruki', 'Togishi'];

// Sale group position Text
export const SALE_GROUP_POSITION_TEXT = {
  leader: 'Trưởng nhóm',
  vice_team: 'Phó hội',
  member: 'Thành viên'
}

export const CATEGORY_PAGE_CONTENT_TYPE_UNI = [
  { value: 'hoc_vien_best_me', text: 'Học viện Best Me' }
];

export const NOTIFICATION_TYPE_SYSTEM = [
  { value: 'offer', text: 'Ưu đãi' },
  { value: 'order', text: 'Đơn hàng' },
  { value: 'reward', text: 'Thưởng' },
  { value: 'training', text: 'Đào tạo' },
  { value: 'system', text: 'Hệ thống' }
];

export const NOTIFICATION_TYPE_SYSTEM_TEXT = {
  offer: 'Ưu đãi',
  order: 'Đơn hàng',
  reward: 'Thưởng',
  training: 'Đào tạo',
  system: 'Hệ thống'
};

export const POST_TYPES = [
  { value: 'product', text: 'Sản phẩm' },
  { value: 'invite_friend', text: 'Mời bạn bè' }
];

export const POST_TYPES_TEXT = {
  product: 'Sản phẩm',
  invite_friend: 'Mời bạn bè'
};

// screen notification
export const SCREEN_NOTIFICATION = [
  // { value: 'sc_post', text: 'Bài đăng sản phẩm' },
  // { value: 'sc_training', text: 'Đào tạo' },
  { value: 'sc_category', text: 'Danh mục sản phẩm' },
  // { value: 'sc_voucher', text: 'Khuyến mãi' },
  // { value: 'sc_invite_friend', text: 'Mời bạn có thưởng' },
  // { value: 'sc_top', text: 'Đua TOP nhận thưởng' },
  // { value: 'sc_customer', text: 'Khách hàng' },
  // { value: 'sc_loyal_partner', text: 'Khách hàng thân thiết' },
  // { value: 'sc_bonus', text: 'Chương trình thưởng' },
  // { value: 'sc_flash_sale', text: 'Flash sale' },
  // { value: 'sc_home', text: 'Trang chủ' },
  // { value: 'sc_search', text: 'Tìm kiếm sản phẩm' },
  { value: 'sc_product', text: 'Chi tiết sản phẩm' },
  // { value: 'sc_cart', text: 'Giỏ hàng' },
  // { value: 'sc_add_to_cart', text: 'Thêm vào giỏ hàng' },
  // { value: 'sc_order', text: 'Thanh toán' },
  // { value: 'sc_thank_you', text: 'Cảm ơn' },
  // { value: 'sc_account', text: 'Tài khoản' },
  // { value: 'sc_partner_info', text: 'Thông tin tài khoản' },
  // { value: 'sc_transaction', text: 'Giao dịch' },
  // { value: 'sc_view_product', text: 'Sản phẩm đã xem' },
  // { value: 'sc_notify', text: 'Thông báo' },
];